var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "excel-import" }, [
    _c("input", {
      ref: "fileInput",
      staticClass: "hidden",
      attrs: { type: "file", accept: ".xlsx, .xls" },
      on: { change: _vm.handleClick },
    }),
    _c(
      "div",
      {
        staticClass:
          "px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl",
        on: {
          click: function ($event) {
            return _vm.$refs.fileInput.click()
          },
          drop: _vm.handleDrop,
          dragover: _vm.handleDragover,
          dragenter: _vm.handleDragover,
        },
      },
      [
        _c("feather-icon", {
          staticClass: "block",
          attrs: {
            icon: "UploadCloudIcon",
            svgClasses: "h-16 w-16 stroke-current text-grey",
          },
        }),
        _c("span", [_vm._v("Drop Excel File or ")]),
        _c(
          "span",
          {
            staticClass: "font-medium text-primary",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$refs.fileInput.click()
              },
            },
          },
          [_vm._v("Browse")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }