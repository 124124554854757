var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-8" },
        [_c("import-excel", { attrs: { onSuccess: _vm.loadDataInTable } })],
        1
      ),
      _vm.tableData.length && _vm.header.length
        ? _c(
            "vx-card",
            [
              _c(
                "vs-table",
                {
                  attrs: {
                    stripe: "",
                    pagination: "",
                    "max-items": 10,
                    search: "",
                    data: _vm.tableData,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return _vm._l(data, function (tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr, attrs: { data: tr } },
                              _vm._l(data[indextr], function (col, indexcol) {
                                return _c(
                                  "vs-td",
                                  { key: indexcol + col, attrs: { data: col } },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(col) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          })
                        },
                      },
                    ],
                    null,
                    false,
                    3112142431
                  ),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", [_vm._v(_vm._s(_vm.sheetName))]),
                  ]),
                  _c(
                    "template",
                    { slot: "thead" },
                    _vm._l(_vm.header, function (heading) {
                      return _c(
                        "vs-th",
                        { key: heading, attrs: { "sort-key": heading } },
                        [_vm._v(_vm._s(heading))]
                      )
                    }),
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }